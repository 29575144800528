const menuToggleBtn = document.getElementById('menu-toggle');
const siteHeader = document.getElementById('site-header');
const modificationsFormEl = document.getElementById('_form_17_');
const subscriptionFormEl = document.getElementById('_form_19_');

menuToggleBtn.addEventListener('click', function () {
    menuToggleBtn.classList.toggle('is-active');
    siteHeader.classList.toggle('is-active');
    document.body.classList.toggle('has-overlay');
});

function floatFormLabels(form) {
    if (form.length > 0) {
        const inputs = form.querySelectorAll('input');
        const textareas = form.querySelectorAll('textarea');

        inputs.forEach((e) => {
            if (e.value.length > 0) {
                e.parentElement.classList.add('is-filled');
            }

            e.addEventListener('blur', (el) => {
                if (el.target.value.length > 0) {
                    el.target.parentElement.classList.add('is-filled');
                } else {
                    el.target.parentElement.classList.remove('is-filled');
                }
            });
        });

        textareas.forEach((e) => {
            if (e.value.length > 0) {
                e.parentElement.classList.add('is-filled');
            }

            e.addEventListener('blur', (el) => {
                if (el.target.value.length > 0) {
                    el.target.parentElement.classList.add('is-filled');
                } else {
                    el.target.parentElement.classList.remove('is-filled');
                }
            });
        });
    }
}

if (modificationsFormEl) {
    floatFormLabels(modificationsFormEl);
}

if (subscriptionFormEl) {
    floatFormLabels(subscriptionFormEl);
}

const swiperEl = document.querySelectorAll('.swiper-container');
if (swiperEl && swiperEl.length > 1) {
    swiperEl.forEach((swiper) => {
        const slidesPerView = +swiper.dataset.slidesperview;
        let breakpoints = {};

        if (slidesPerView > 1) {
            breakpoints = {
                640: {
                    slidesPerView,
                    spaceBetween: 40,
                },
            };
        }

        const mySwiper = new Swiper(swiper, {
            // Optional parameters
            slidesPerView: 1,
            spaceBetween: 30,
            loop: true,
            autoHeight: true,

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            // Breakpoints
            breakpoints,
        });
    });
}
